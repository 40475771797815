.logo-img {
    height: 108px;
}

.custom-banner-img {
    height: 100%;
}

.marquee-container {
    height: 500px;
}

.my {
    height: 350px;
}

.mlogo {
    width: 150px;
}

.android {
    width: 100px;
    float: left;
}

.breadcrumb_content {
    display: flex;
    flex-direction: column;
}

.single-work {
    height: 325px;
}

.table.table-bordered.table-hover.table-responsive {
    display: inline-table;
}

/* .custom-investor-table {
    border: 1px solid;
    margin: 0 auto;
} */

table.custom-investor-table,
.custom-investor-table td,
.custom-investor-table th {
    border: 1px solid;
}

table.custom-investor-table {
    width: 100%;
    border-collapse: collapse;
}

.mdName {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 20px;
}

table#interestrate,
table#depointerestrate {
    width: 100%;
}

.digLogoQR img {
    width: 180px;
    height: 180px;
    margin-bottom: 10px;
}

.digLogoQR {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}